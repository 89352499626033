<template>
  <div>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <Settings />
  </div>
</template>

<script>
// @ is an alias to /src
import Settings from "@/components/Settings.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Settings,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Profilo",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Impostazioni",
      path: "Settings",
      level: 2,
    });
  },
};
</script>
<style scoped>
.settings {
  text-align: center;
}
</style>
