<template>
  <div class="px-4 mt-2">
    <b-tabs content-class="pt-1 mb-4">
      <b-tab title="Contabilità" active title-item-class="lisaweb">
        <b-row>
          <div class="col-md-4 mt-2" v-if="!isLoading">
            <base-radio
              vid="progressive"
              name="progressive"
              label="Foglio Quadratura Progressivo"
              v-model="form['agency_setup'].attribute_ACCT_value"
              :options="
                Object.values(
                  beForm['agency_setup'].attribute_ACCT_value.options
                ).map((o) => {
                  return { value: o.value, text: o.text };
                })
              "
              @select="onProgressiveChange"
            />
          </div>
          <!-- <div
            class="col-md-4 mt-2"
            v-if="form['agency_setup'].attribute_ACCT_value === 'Y'"
          >
            <p>
              Data Ultimo Consolidamento ::
              {{ lastConsDate ? lastConsDate : "--" }}
            </p>
          </div> -->
        </b-row>
        <b-overlay center :show="isLoading" rounded="sm">
          <!-- progressive -->
          <b-row>
            <div v-show="!isLoading && isProgressiveSelected" class="col-md-12">
              <b-card header="true" header-tag="header">
                <div
                  slot="header"
                  class="d-flex justify-content-between align-items-center"
                >
                  <p class="mb-0 d-inline-block align-middle">
                    <b>
                      {{
                        canConsolidate
                          ? "Nuova Apertura Saldi"
                          : "Saldi per il giorno " +
                            toLocaleDate(consolidatedAt)
                      }}
                    </b>
                  </p>
                </div>
                <div class="col-md-4">
                  <base-datepicker
                    vid="consolidated_at"
                    name="consolidated_at"
                    :label="
                      canConsolidate
                        ? 'Data di nuova apertura saldi'
                        : 'Data saldi'
                    "
                    v-model="form.consolidated_at"
                    :max="today()"
                    @select="onDate"
                  />
                </div>
                <div
                  v-if="
                    isProgressiveSelected &&
                    consolidatedAt &&
                    (canConsolidate || readonlyConsolidated)
                  "
                >
                  <b-form @submit.prevent="">
                    <!-- <b-card header-tag="header" class="mt-1"> -->
                    <!-- <template #header>
                  <div>
                    {{
                      canConsolidate ? "Nuovo Consolidamento" : "Consolidamento"
                    }}
                    per il giorno
                    {{ toLocaleDate(consolidatedAt) }}
                  </div>
                </template> -->
                    <!-- <b-card-text> -->
                    <p class="text-uppercase">
                      <!-- <b>Valori nuova apertura saldi</b> -->
                      <b>
                        {{
                          canConsolidate
                            ? "Valori nuova apertura saldi"
                            : "Valori saldi per il giorno " +
                              toLocaleDate(consolidatedAt)
                        }}
                      </b>
                    </p>
                    <table
                      v-if="Object.keys(form['consolidated_treasury']).length"
                    >
                      <thead>
                        <tr>
                          <th class="verticalLine">Compagnia</th>
                          <th
                            v-for="(item, index) in getSectorCodes(
                              sectorIndex,
                              'N',
                              'N',
                              'N',
                              'N',
                              'title'
                            )"
                            :key="item.code"
                            :class="{
                              verticalLine:
                                index ===
                                getSectorCodes(sectorIndex, 'N', 'N', 'N', 'N')
                                  .length -
                                  1,
                            }"
                          >
                            {{ item }}
                          </th>
                          <th
                            v-for="(item, index) in getSectorCodes(
                              sectorIndex,
                              'Y',
                              'N',
                              'N',
                              'N',
                              'title'
                            )"
                            :key="item.code"
                            :class="{
                              verticalLine:
                                index ===
                                getSectorCodes(sectorIndex, 'Y', 'N', 'N', 'N')
                                  .length -
                                  1,
                            }"
                          >
                            {{ item }}
                          </th>
                          <th
                            v-for="item in getSectorCodes(
                              sectorIndex,
                              'Y',
                              'Y',
                              'N',
                              'N',
                              'title'
                            )"
                            :key="item.code"
                          >
                            {{ item }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(treasuryGross, id) in form[
                            'consolidated_treasury'
                          ]"
                          :key="'tr-' + id"
                        >
                          <td
                            :class="{
                              verticalLine: true,
                              mw: true,
                            }"
                          >
                            {{
                              parseInt(id)
                                ? `${insurers.find((e) => e.id == id).code} - ${
                                    insurers.find((e) => e.id == id).title
                                  }`
                                : "Indefinita"
                            }}
                          </td>
                          <td
                            v-for="(code, index1) in getSectorCodes(
                              sectorIndex,
                              'N',
                              'N',
                              'N',
                              'N'
                            )"
                            :class="{
                              verticalLine:
                                index1 ===
                                getSectorCodes(sectorIndex, 'N', 'N', 'N', 'N')
                                  .length -
                                  1,
                              xsw: true,
                            }"
                            :key="'1.' + index1"
                          >
                            <!-- add the treasuries -->
                            <base-currency
                              :name="'gross-' + id + '-' + code"
                              :vid="'gross-' + id + '-' + code"
                              label=""
                              v-model="form[ct][id][code]"
                              :options="{
                                currency: 'EUR',
                                locale: 'it-IT',
                                precision: 2,
                              }"
                              :readonly="readonlyConsolidated"
                            />
                          </td>

                          <!-- section: !is_system && !is_abstract -->
                          <td
                            v-for="(code, index2) in getSectorCodes(
                              sectorIndex,
                              'Y',
                              'N',
                              'N',
                              'N'
                            )"
                            :class="{
                              verticalLine:
                                index2 ===
                                getSectorCodes(sectorIndex, 'Y', 'N', 'N', 'N')
                                  .length -
                                  1,
                              xsw: true,
                            }"
                            :key="'2.' + index2"
                          >
                            <!-- add the treasuries -->
                            <base-currency
                              :name="'gross-' + id + '-' + code"
                              :vid="'gross-' + id + '-' + code"
                              label=""
                              v-model="form[ct][id][code]"
                              :options="{
                                currency: 'EUR',
                                locale: 'it-IT',
                                precision: 2,
                              }"
                              :readonly="readonlyConsolidated"
                            />
                          </td>

                          <!-- section: is_system && is_abstract -->
                          <td
                            v-for="(code, index3) in getSectorCodes(
                              sectorIndex,
                              'Y',
                              'Y',
                              'N',
                              'N'
                            )"
                            :key="'3.' + index3"
                            :class="{ xsw: true }"
                          >
                            <!-- add the treasuries -->
                            <base-currency
                              :name="'gross-' + id + '-' + code"
                              :vid="'gross-' + id + '-' + code"
                              label=""
                              v-model="form[ct][id][code]"
                              :options="{
                                currency: 'EUR',
                                locale: 'it-IT',
                                precision: 2,
                              }"
                              :readonly="readonlyConsolidated"
                            />
                          </td>
                        </tr>
                        <tr class="totals">
                          <!-- totali -->
                          <td class="verticalLine">Totale</td>
                          <td
                            v-for="(code, index1) in getSectorCodes(
                              sectorIndex,
                              'N',
                              'N',
                              'N',
                              'N'
                            )"
                            :class="{
                              verticalLine:
                                index1 ===
                                getSectorCodes(sectorIndex, 'N', 'N', 'N', 'N')
                                  .length -
                                  1,
                            }"
                            :key="'T1.' + index1"
                          >
                            <!-- add the treasuries total -->
                            {{ toLocaleCurrency(getSectorGrossTotals(code)) }}
                          </td>

                          <!-- section: !is_system && !is_abstract -->
                          <td
                            v-for="(code, index2) in getSectorCodes(
                              sectorIndex,
                              'Y',
                              'N',
                              'N',
                              'N'
                            )"
                            :class="{
                              verticalLine:
                                index2 ===
                                getSectorCodes(sectorIndex, 'Y', 'N', 'N', 'N')
                                  .length -
                                  1,
                            }"
                            :key="'T2.' + index2"
                          >
                            <!-- add the treasuries total -->
                            {{ toLocaleCurrency(getSectorGrossTotals(code)) }}
                          </td>

                          <!-- section: is_system && is_abstract -->
                          <td
                            v-for="(code, index3) in getSectorCodes(
                              sectorIndex,
                              'Y',
                              'Y',
                              'N',
                              'N'
                            )"
                            :key="'T3.' + index3"
                          >
                            <!-- add the treasuries total -->
                            {{ toLocaleCurrency(getSectorGrossTotals(code)) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-else>Nessun movimento da mostrare.</div>
                    <!-- </b-card-text> -->
                    <!-- </b-card> -->
                    <div class="mt-2">
                      <!-- aggiorno progressive direttaente sul radio change -->
                      <b-button
                        v-if="canConsolidate && !readonlyConsolidated"
                        @click="onSubmit(ct)"
                        variant="lisaweb"
                        class="float-left"
                        >Salva</b-button
                      >
                    </div>
                  </b-form>
                </div>
              </b-card>
            </div>
          </b-row>
          <template #overlay>
            <div class="text-center mt-5">
              <base-icon name="loading" width="35" height="35" />
              <p id="cancel-label">{{ msg || "Operazione in corso..." }}</p>
            </div>
          </template>
        </b-overlay>
      </b-tab>
      <b-tab title="Altro" title-item-class="lisaweb">
        <b-row>
          <div class="col-md-4 mt-2" v-if="!isLoading">
            <base-radio
              vid="broker_statement"
              name="broker_statement"
              label="Modalità Rendiconto"
              v-model="form['agency_setup'].attribute_STATMOD_value"
              :options="beForm['agency_setup'].attribute_STATMOD_value.options"
              @select="onBrokerStatementChange"
            />
          </div>
        </b-row>
      </b-tab>
      <b-tab
        title="Assistenza"
        :disabled="!isAssistence"
        title-item-class="lisaweb"
        ><p>Assistenza</p></b-tab
      >
      <!-- :isAssistence="roles.includes('admin-assistenza')" -->
    </b-tabs>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("it");
import { mapGetters } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { today, toLastDay, toLocaleDate } from "@/utils/dates";
import BaseIcon from "@/components/BaseIcon";
import BaseRadio from "@/components/form/BaseRadio";
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import FormMixin from "@/mixins/FormMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import {
  getTreasuryCodes,
  getSectorCodes,
  sumGrossTotals,
} from "@/utils/accounting";
export default {
  mixins: [FormMixin, ConfirmModalMixin],
  data() {
    return {
      isLoading: false,
      insurers: [],
      treasuryIndex: [],
      sectorIndex: [],
      mandatoryFields: {},
      form: {
        consolidated_at: null,
        agency_setup: {
          attribute_ACCT_value: null,
          attribute_STATMOD_value: null,
        },
        consolidated_treasury: {},
      },
      calMonth: null,
      calYear: null,
      actualMonth: null,
      actualYear: null,
      consolidatedDates: [],
      maxDate: null,
      canConsolidate: false,
      readonlyConsolidated: false,
    };
  },
  components: {
    BaseIcon,
    BaseRadio,
    BaseCurrency,
    BaseDatepicker,
  },
  methods: {
    toLocaleDate,
    toLocaleCurrency,
    getTreasuryCodes,
    getSectorCodes,
    today,
    toLastDay,
    initDefaultValues() {
      this.calMonth = moment().format("MM");
      this.calYear = moment().format("YYYY");
      this.actualMonth = moment().format("MM");
      this.actualYear = moment().format("YYYY");
    },
    initForm(name) {
      let codes;
      let allInsurers = [{ id: null, code: "UND" }].concat(this.insurers);
      switch (name) {
        case "consolidated_treasury":
          codes = this.sectorIndex.map((t) => t.code);
          this.$set(this.form, name, {});
          for (const insurer of allInsurers) {
            let id = insurer.id;
            this.$set(this.form[name], id, {});
            for (const code of codes) {
              this.$set(this.form[name][id], code, 0);
            }
          }
          console.debug("consolidated_treasury form: ", this.form[name]);
          break;
        default:
          break;
      }
    },
    /**
     * form contains gross values by insurer and sector
     * data param contains gross values by insurer and treasury
     * filling form values must aggregate gross values in data by sector
     */
    initFormValues(name, data) {
      this.initForm(name);
      for (const d of data) {
        const id = d.insurer_id;
        const treasury_id = d.treasury_id;
        const gross = d.gross;
        // aggregate grosses of treasuries belonging to the same sector
        const sector_id = this.treasuryIndex.find(
          (e) => e.id === treasury_id
        ).sector_id;
        const sector_code = this.sectorIndex.find(
          (e) => e.id === sector_id
        ).code;
        const aggr_gross = this.form[name][id][sector_code] + gross;
        this.$set(this.form[name][id], sector_code, aggr_gross);
      }
    },
    getSectorGrossTotals(code) {
      let totals = sumGrossTotals(
        Object.values(this.form[this.ct]),
        this.sectorIndex
      );
      return totals[code];
    },
    checkAllZeroes(name) {
      let ret = true;
      for (const obj of Object.values(this.form[name])) {
        // almeno 1 non è zero...
        if (Object.values(obj).some((e) => e > 0)) {
          ret = false;
          break;
        }
      }
      return ret;
    },
    calcPartialGrosses(amount, parts) {
      let ret = [];
      const part_amount = parseFloat(parseInt(amount / parts));
      const last_part_amount = parseFloat(
        (amount - part_amount * (parts - 1)).toFixed(2)
      );
      ret.push(last_part_amount); // l'eccedente sulla prima cassa
      let i = 1;
      while (i < parts) {
        ret.push(part_amount);
        i++;
      }
      return ret;
    },
    storeAll(name) {
      let Repo;
      // let keysTreasuryMap;
      let keysSectorMap;
      let payload = {};
      // let promises = [];
      this.loadMsg();
      this.isLoading = true;
      Repo = RepositoryFactory.get(name);
      keysSectorMap = Object.assign(
        {},
        ...this.sectorIndex.map((item) => ({
          [item.code]: item.id,
        }))
      );
      // devo salvare il form, riga per riga (1 insurer), colonna per colonna (1 treasury)
      let p_key = 0; // key per il payload "massivo"
      for (const [key, obj] of Object.entries(this.form[name])) {
        for (const sector_code in obj) {
          // get the treasury codes associated with this sector
          const sector_id = parseInt(keysSectorMap[sector_code]);
          const treasury_ids = this.treasuryIndex
            .filter((e) => e.sector_id === sector_id)
            .map((item) => item.id);
          /* .map((item) => ({
              [item.code]: item.id,
            })) */
          const partial_grosses = this.calcPartialGrosses(
            obj[sector_code],
            treasury_ids.length
          );

          // BULK
          let index = 0;
          for (const treasury_id of treasury_ids) {
            payload[p_key] = {
              insurer_id:
                key && key != "null" && parseInt(key) ? parseInt(key) : null,
              treasury_id,
              gross: partial_grosses[index],
              consolidated_at: this.form.consolidated_at,
            };
            index++;
            p_key++;
          }
        }
      }
      // Promise.all(promises)
      Repo.bulk_store(payload)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Consolidamento Creato al ${this.form.consolidated_at}`,
          });
          // imposta la data ultimo consolidamento (a video)
          this.maxDate = this.form.consolidated_at;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.unloadMsg();
          this.isLoading = false;
        });
    },
    onSubmit(name) {
      let allZeroes = true;
      switch (name) {
        case this.ct:
          allZeroes = this.checkAllZeroes(name);
          if (allZeroes) {
            // ask before save
            this.showConfirm({
              yesCallback: () => {
                this.storeAll(name);
              },
              noCallback: null,
              title: "Conferma Creazione",
              message:
                "Creare una nuova apertura saldi con tutti i valori a zero?",
              yesLabel: "CREA",
              noLabel: "ANNULLA",
              // yesVariant:
              // noVariant:
              // headerBgVariant:
            });
          } else {
            // this.storeAll(name);
            this.showConfirm({
              yesCallback: () => {
                this.storeAll(name);
              },
              noCallback: null,
              title: "Conferma Creazione",
              message: "Confermi di voler creare una nuova apertura saldi?",
              yesLabel: "CREA",
              noLabel: "ANNULLA",
              // yesVariant:
              // noVariant:
              // headerBgVariant:
            });
          }

          // REVIEW si spera che il BE riesca almeno a salvare tutti i treasury insieme
          // CHECK mass store ???
          // payload = {};
          // for(const key in Object.keys(this.form[name])) {
          //   payload[key] = []
          //   let obj = this.form[name][key];
          //   for(const code in Object.keys(obj)) {
          //     payload[key].push({
          //       insurer_id: key ? key : null,
          //       treasury_id: parseInt(keysMap[code]),
          //       gross: obj[code],
          //       consolidated_at: this.form.consolidated_at
          //     })
          //   }
          // }

          // Repo.store(payload)
          //   .then(() => {
          //     this.commitProgressive();
          //     showSnackbar({
          //       preset: "success",
          //       text: `Azione Completata: Consolidamento Creato per il giorno ${this.form.consolidated_at}`,
          //     });
          //     this.isLoading = false;
          //   })
          // .catch((error) => {
          //   let errMsg = this.$getErrorMessage(error);
          //   showSnackbar({
          //     preset: "error",
          //     text: `${errMsg}`,
          //   });
          // })
          // .finally(() => {
          //   this.isLoading = false;
          // })
          break;
        default:
          this.isLoading = false;
          break;
      }
    },
    onProgressiveChange(checkedOption) {
      if (checkedOption === "N") {
        // confirm
        this.showConfirm({
          yesCallback: () => {
            this.form["agency_setup"].attribute_ACCT_value = checkedOption;
            this.updateProgressive();
          },
          // noCallback: null,
          noCallback: () => {
            this.form["agency_setup"].attribute_ACCT_value = "Y";
          },
          title: "Conferma operazione",
          message: `ATTENZIONE : stai passando dalla modalità di consolidatura PROGRESSIVA alla modalità di consolidatura NON PROGRESSIVA. Se confermi, verranno azzerati i saldi di apertura e la relativa data attualmente memorizzati, di conseguenza se dovessi decidere in seguito di ritornare alla modalità progressiva dovrai ricaricare data e saldi di apertura.`,
          yesLabel: "PASSA",
          noLabel: "ANNULLA",
          // yesVariant:
          // noVariant:
          // headerBgVariant:
        });
      } else {
        this.form["agency_setup"].attribute_ACCT_value = checkedOption;
        this.updateProgressive();
      }

      // .then(() => {
      //   // #937 al passaggio da progressivo a non progressivo
      //   if (checkedOption === "N") {
      //     // show warning
      //     this.$showSnackbar({
      //       preset: "info",
      //       text: "ATTENZIONE : stai passando dalla modalità di consolidatura PROGRESSIVA alla modalità di consolidatura NON PROGRESSIVA. Se confermi, verranno azzerati i saldi di apertura e la relativa data attualmente memorizzati, di conseguenza se dovessi decidere in seguito di ritornare alla modalità progressiva dovrai ricaricare data e saldi di apertura.",
      //       duration: 0,
      //     });
      //   }
      // })
      // .catch((error) => {
      //   let errMsg = this.$getErrorMessage(error);
      //   this.$showSnackbar({
      //     preset: "error",
      //     text: errMsg,
      //   });
      // });
    },
    onBrokerStatementChange(checkedOption) {
      this.form["agency_setup"].attribute_STATMOD_value = checkedOption;
      this.updateBrokerStatementMode();
    },
    updateBrokerStatementMode() {
      // save both to BE and to vuex store
      let id = this.getSetting()("id");
      /*
      let form = {
        attribute_STATMOD_value:
          this.form["agency_setup"].attribute_STATMOD_value,
      };
      let payload = { ...this.mandatoryFields["agency_setup"] };
      this.setMandatoryValues("agency_setup", payload);
      payload = { ...payload, ...form };
      Repo.update(id, payload)
      */
      this.update("agency_setup", id, this.form["agency_setup"])
        .then((response) => {
          const brokerStatementLabel = this.beForm[
            "agency_setup"
          ].attribute_STATMOD_value.options.find(
            (option) =>
              option.value === this.form["agency_setup"].attribute_STATMOD_value
          ).text;
          this.$showSnackbar({
            preset: "success",
            text: `Modalità Rendiconto ${brokerStatementLabel}: aggiornata con successo`,
          });
          this.commitBrokerStatementMode(response.data.data);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    commitBrokerStatementMode(data) {
      // call store mutator to set the new value
      this.$store.commit("auth/SETTING_SAVE", {
        name: "attribute_STATMOD_value",
        value: this.form["agency_setup"].attribute_STATMOD_value,
      });
      this.$store.commit("auth/SETTING_SAVE", {
        name: "attributables",
        value: data.attributables,
      });
    },
    onDate(date) {
      // Se in quella data o in una qualsiasi data successiva
      // ci sono già righe di consolidatura non consente con errore.
      // this.canConsolidate = false;
      // this.readonlyConsolidated = false;
      let format = date.indexOf("/") !== -1 ? "DD/MM/YYYY" : "YYYY-MM-DD";
      if (!date || !moment(date, format).isValid()) {
        this.canConsolidate = false;
        return;
      }
      // typed date validity
      // if (moment(date, "YYYY-MM-DD").isAfter(this.today(), "day")) {
      if (moment(date, format).isAfter(this.today(), "day")) {
        let errMsg = `La data non può essere successiva a ${toLocaleDate(
          this.today()
        )}`;
        this.$showSnackbar({
          preset: "info",
          text: `${errMsg}`,
        });
        this.form.consolidated_at = null;
        // date = this.today().format(format);
        date = this.today(format);
        this.canConsolidate = true;
      }
      this.isLoading = true;
      this.loadConsolidatedTreasuries(
        moment(date, format).format("YYYY-MM-DD")
      ).then(() => {
        this.isLoading = false;
      });
    },
    commitProgressive() {
      // call store mutator to set the new value
      this.$store.commit("auth/SETTING_SAVE", {
        name: "attribute_ACCT_value",
        value: this.form["agency_setup"].attribute_ACCT_value,
      });
    },
    updateProgressive() {
      // save both to BE and to vuex store
      let id = this.getSetting()("id");
      /*
      let form = {
        attribute_ACCT_value: this.form["agency_setup"].attribute_ACCT_value,
      };
      let payload = { ...this.mandatoryFields["agency_setup"] };
      this.setMandatoryValues("agency_setup", payload);
      payload = { ...payload, ...this.form };
      Repo.update(id, payload)
      */
      return this.update("agency_setup", id, this.form["agency_setup"])
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            // text: "Progressivo aggiornato con successo",
            text: `Foglio ${
              this.form["agency_setup"].attribute_ACCT_value === "N"
                ? "non"
                : ""
            } progressivo: aggiornato con successo`,
          });
          // call store mutator to set the new value
          this.commitProgressive();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    fetchConsolidatedDates(from, to) {
      let Repo = RepositoryFactory.get("consolidated_treasury");
      if (!from) from = `${this.calYear}-${this.calMonth}-01`;
      if (!to) to = this.toLastDay(`${this.calYear}-${this.calMonth}-01`);
      let queryString = `perPage=0&byDateConsolidated=${from},${to}`;
      return Repo.index(queryString)
        .then((response) => {
          this.consolidatedDates = response.data.data;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    loadConsolidatedTreasuries(date) {
      // this.$showSnackbar({
      //   preset: "info",
      //   text: "loadConsolidatedTreasuries",
      // });
      // set progressive, and if true ask for the last consolidated_at date...
      if (
        (this.form["agency_setup"].attribute_ACCT_value = this.getSetting()(
          "attribute_ACCT_value"
        ))
      ) {
        let md;
        if (!date) {
          md = moment();
        } else {
          md = moment(date, "YYYY-MM-DD");
        }
        let from = `${md.format("YYYY")}-${md.format("MM")}-01`;
        // let to = this.toLastDay(`${md.format("YYYY")}-${md.format("MM")}-01`);
        return this.fetchConsolidatedDates(from)
          .then(() => {
            // check if can consolidate ... then set canConsolidate
            let arrM = this.consolidatedDates.map((d) =>
              moment(d.consolidated_at)
            );
            this.maxDate = arrM.length
              ? moment.max(arrM).format("YYYY-MM-DD")
              : null;
            let arrD = this.consolidatedDates.map((d) => d.consolidated_at);
            // if (this.maxDate) {
            let found = arrD.some((e) =>
              moment(e, "YYYY-MM-DD").isSameOrAfter(
                moment(md, "YYYY-MM-DD"),
                "day" // granularity
              )
            );
            if (!found) {
              this.$set(this.form, "consolidated_at", md.format("YYYY-MM-DD"));
              this.initForm(this.ct);
              this.canConsolidate = true;
              this.readonlyConsolidated = false;
              // this.isLoading = false;
            } else {
              this.canConsolidate = false;
              this.readonlyConsolidated = true;
              this.$showSnackbar({
                preset: "info",
                text: `Esiste già un consolidamento per una data uguale o successiva a ${md.format(
                  "DD/MM/YYYY"
                )} => [ ${moment(this.maxDate, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )} ].`,
              });
              // // imposta il datepicker a maxDate?
              // this.$set(this.form, "consolidated_at", this.maxDate);
              // imposta il datepicker a md?
              this.$set(this.form, "consolidated_at", md.format("YYYY-MM-DD"));
              // carica i dati da disegnare nella "griglia"
              let Repo = RepositoryFactory.get(this.ct);
              // let queryString = `perPage=0&byCalendar=${this.maxDate},${this.maxDate}`;
              let queryString = `perPage=0&byCalendar=${md.format(
                "YYYY-MM-DD"
              )},${md.format("YYYY-MM-DD")}`;
              Repo.index(queryString)
                .then((response) => {
                  // this.readonlyConsolidated = response.data.data.length
                  //   ? true
                  //   : false;
                  // inizializza il form
                  this.initFormValues(this.ct, response.data.data);
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({ preset: "error", text: errMsg });
                });
            }
            // } else {
            //   // inizializza il form
            //   this.initForm(this.ct);
            //   this.canConsolidate = true;
            //   this.readonlyConsolidated = false;
            //   // this.form.consolidated_at = this.today();
            //   this.form.consolidated_at = md.format("YYYY-MM-DD");
            // }
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          });
      }
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getRoles: "roles",
      getSetting: "setting",
    }),
  },
  created() {
    this.initDefaultValues();
    // this.isLoading = true;
    this.loadConsolidatedTreasuries();
  },
  beforeMount() {
    this.isLoading = true;
    // siccome carico sempre i dati al cambio data, non solo al cambio mese
    // NON serve caricarli nel beforeMount!
    // this.fetchConsolidatedDates();
    this.sectorIndex = this.$store.state.auth.sectors;
    this.treasuryIndex = this.$store.state.auth.treasuries;
    this.insurers = this.$store.state.auth.insurers;
    // init the consolidated_treasury form
    this.initForm(this.ct);
    // // set progressive (alreatu set in created hook)
    // this.form["agency_setup"].attribute_ACCT_value = this.getSetting()("attribute_ACCT_value");
    // TODO devo chiamare la agency_setups edit form e inizializzare i mandatory fields per poter salvare attribute_ACCT_value
    let id = this.getSetting()("id");
    this.fetchEditForm("agency_setup", id)
      .then(() => {
        this.initMandatoryFields("agency_setup");
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
      });
  },
  computed: {
    isProgressiveSelected() {
      return this.form["agency_setup"].attribute_ACCT_value === "Y";
    },
    isAssistence() {
      return this.getRoles().includes("admin-assistenza");
    },
    consolidatedAt() {
      return this.form.consolidated_at;
    },
    lastConsDate() {
      return this.maxDate;
    },
    ct() {
      return "consolidated_treasury";
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
table tr th,
td {
  padding: 2px 4px;
  text-align: right;
}
.xsw {
  width: 6%;
  min-width: 80px;
  max-width: 160px;
}
.sw {
  width: 8%;
  min-width: 100px;
  max-width: 200px;
}
.mw {
  width: 10%;
  min-width: 120px;
  max-width: 240px;
}
.verticalLine {
  border-right: 2px solid #f9991e;
}
.totals {
  background-color: #e1ddda;
  font-weight: bold;
}
</style>
